<template>
  <div>
    <b-table :items="statusList" :fields="table.fields" small striped>
      <template #cell(provider)="data">
        <span class="font-weight-bolder">{{ data.item.provider }}</span>
      </template>
      <template #cell(status)="data">
        <div class="text-center">
          <b-badge :variant="getStatusVariant(data.item.status)">
            {{ data.item.status }}
          </b-badge>
        </div>
      </template>
      <template #cell(lastCheck)="data">
        <span class="font-italic"> {{ data.item.lastCheck | timeAgo }} </span>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: `NawalaStatusDetail`,
  props: {
    statusList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      table: {
        fields: [
          {
            key: "provider",
            label: "Provider",
          },
          {
            key: "lastCheck",
            label: "Last Check",
            class: `text-right`,
          },
          { key: "status", label: "Status", class: `text-center` },
        ],
      },
    };
  },
  methods: {
    getStatusVariant(status) {
      switch (status) {
        case "SAFE":
          return "success";
        case "BLOCKED":
          return "danger";
        case "FORBIDDEN":
          return "warning";
        default:
          return "secondary";
      }
    },
  },
};
</script>

import StatusDetail from "./StatusDetail.vue";
import api from "@api";
import { mapState } from "vuex";

export default {
  name: `NawalaList`,
  components: { StatusDetail },
  props: {
    clientCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      newSlotCount: null,
      search: null,
      selectedRow: null,
      editingRow: null,
      table: {
        items: [],
        fields: [
          { key: "select", label: "", onlyAdmin: true, thStyle: `width: 40px` },
          {
            key: "description",
            label: `Description`,
            filterable: true,
          },
          {
            key: "domain",
            label: `URL`,
            filterable: true,
          },
          {
            key: "status",
            label: `Status`,
            class: `text-right`,
          },
          {
            key: "action",
            label: "",
            class: `text-right`,
            thClass: `action-column`,
            tdClass: `action-column`,
          },
        ],
        paging: {
          pageNo: 1,
          pageSize: 10,
        },
        selectedRows: [],
        isSelectAll: false,
      },
      statusList: [
        { value: null, text: "All Status" },
        { value: "BLOCKED", text: "BLOCKED" },
        { value: "SAFE", text: "SAFE" },
        { value: "FORBIDDEN", text: "FORBIDDEN" },
      ],
      filter: {
        description: null,
        domain: null,
        status: null,
      },
      modal: { status: false },
      loader: false,
    };
  },
  computed: {
    ...mapState(`auth`, [`user`]),
    computedFields() {
      return this.table.fields.filter(x => !x.onlyAdmin || (x.onlyAdmin && this.$can(`super`, `nawala`)));
    },
    filteredItems() {
      let items = this.table.items;
      if (this.filter.description) items = items.filter(x => x.description.toLowerCase().includes(this.filter.description.toLowerCase()));
      if (this.filter.domain) items = items.filter(x => x.domain.toLowerCase().includes(this.filter.domain.toLowerCase()));
      if (this.filter.status) items = items.filter(x => x.status == this.filter.status);
      return items;
    }
  },
  watch: {
    "table.isSelectAll": function (val) {
      this.checkAll(val);
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loader = true;
      try {
        if (this.clientCode) this.table.items = await api.get(`slot/${this.clientCode}`);
        else this.table.items = await api.get(`slot/${this.user.clientCode}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async addNewSlot() {
      let confirmed = await this.showConfirm(`Add New ${this.newSlotCount} Slot?`);
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.post(`slot`, { clientCode: this.clientCode, newSlotCount: this.newSlotCount });
        this.loadData();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }

      this.showToast(`success`, `${this.newSlotCount} Slot Added`);
      this.newSlotCount = null;
    },
    modify(row) {
      row.isEditing = true;
      this.table.items.forEach(x => {
        if (x.code != row.code) x.isEditing = false;
      });
      this.editingRow = { ...row };
      this.$refs.slotTable.refresh();
    },
    async save(row) {
      this.loader = true;
      try {
        await api.put(`slot`, this.editingRow);
        row.isEditing = false;
        this.editingRow = null;
        this.showToast(`success`, `Slot Updated`);
        this.loadData();
        this.$refs.slotTable.refresh();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    cancelEdit(row) {
      row.isEditing = false;
      this.editingRow = null;
      this.$refs.slotTable.refresh();
    },

    async remove(row) {
      let confirmed = await this.showConfirm(`Remove Slot ?`);
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.delete(`slot/${row.code}`);
        this.showToast(`success`, `Slot Removed`);
        this.loadData();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async removeBatch() {
      let confirmed = await this.showConfirm(`Remove All Selected Slot ?`);
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.put(`slot/remove-batch`, { slotCodeList: this.table.selectedRows });
        this.showToast(`success`, `Slot Removed`);
        this.loadData();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async toggle(row) {
      var message = "";
      if (row.isActive == true) {
        message = `Deactivate this Slot ?`;
      }
      else {
        message = `Activate this Slot ?`;
      }
      let confirmed = await this.showConfirm(message);
      if (!confirmed) return;
      this.loader = true;
      try {
        await api.put(`slot/toggle/${row.code}`);
        this.showToast(`success`, `Slot IsActive Changed`);
        this.loadData();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },

    async showStatus(row) {
      this.selectedRow = { ...row };
      this.modal.status = true;
    },

    checkAll(val) {
      if (val) {
        this.table.selectedRows = this.table.items.map(x => x.code);
      } else {
        this.table.selectedRows = [];
      }
    },

    variantRotator(status) {
      switch (status) {
        case "BLOCKED":
          return "danger";
        case "SAFE":
          return "success";
        case "FORBIDDEN":
          return "warning";
        default:
          return "secondary";
      }
    },
  },
};

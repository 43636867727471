<template>
  <div>
    <!-- TODO: APPLY LOGIC TO FILTER STATUS-->
    <b-card class="main-content" body-class="d-flex flex-column">
      <vue-element-loading :active="loader" spinner="bar-fade-scale" />
      <template #header>
        <div>
          <b-input-group v-if="$can('super', 'nawala')">
            <b-form-input v-model="newSlotCount" type="number" placeholder="New Slot Count"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="newSlotCount <= 0" variant="outline-success" size="sm" @click.stop="addNewSlot">
                Add New Slot
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <h5 v-else class="text-secondary">Untuk menambah slot, silahkan hubungi customer service kami</h5>
        </div>

        <b-button
          v-if="$can('super', 'nawala')"
          :disabled="table.selectedRows.length <= 0"
          variant="outline-danger"
          class="ml-1 mr-auto"
          @click.stop="removeBatch"
        >
          Remove Slot
        </b-button>
        <div class="ml-auto">
          <span> Slot Count: </span>
          <b class="text-primary h3">{{ table.items.length <= 0 ? `-` : table.items.length }}</b>
        </div>
      </template>
      <b-table
        ref="slotTable"
        :items="filteredItems"
        :fields="computedFields"
        :current-page="table.paging.pageNo"
        :per-page="table.paging.pageSize"
        striped
        fixed
        small
        show-empty
        responsive
        @row-dblclicked="modify"
      >
        <template #top-row>
          <td v-if="$can(`super`, `nawala`)"></td>
          <td>
            <b-input v-model="filter.description" placeholder="Filter Description" size="sm" debounce="500"></b-input>
          </td>
          <td><b-input v-model="filter.domain" placeholder="Filter Domain" size="sm" debounce="500"></b-input></td>
          <td>
            <b-form-select v-model="filter.status" :options="statusList" size="sm"></b-form-select>
          </td>
          <td></td>
        </template>

        <template #head(select)>
          <b-form-checkbox v-model="table.isSelectAll" />
        </template>

        <template #cell(select)="data">
          <b-form-checkbox v-model="table.selectedRows" :value="data.item.code"></b-form-checkbox>
        </template>

        <template #cell(description)="data">
          <b-input v-if="data.item.isEditing" v-model="editingRow.description" size="sm"></b-input>
          <div v-else>
            <span v-if="data.item.description">{{ data.item.description }}</span>
            <small v-else class="font-italic text-danger">this slot is empty</small>
          </div>
        </template>

        <template #cell(domain)="data">
          <b-input v-if="data.item.isEditing" v-model="editingRow.domain" size="sm"></b-input>
          <div v-else>
            <span v-if="data.item.domain">{{ data.item.domain }}</span>
            <small v-else class="font-italic text-danger">this slot is empty</small>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            class="font-bold cursor-pointer"
            :variant="variantRotator(data.item.status)"
            @click.stop="showStatus(data.item)"
          >
            {{ data.item.status }}
          </b-badge>
          <span v-else class="text-danger"> ~ </span>
        </template>

        <template #cell(action)="data">
          <submit-button v-if="data.item.isEditing" text="" class="mr-1" @click.stop="save(data.item)" />
          <cancel-button v-if="data.item.isEditing" text="" class="mr-1" @click.stop="cancelEdit(data.item)" />
          <edit-button v-if="!data.item.isEditing" class="mr-1" @click.stop="modify(data.item)" />
          <toggle-button v-if="$can(`super`, `nawala`)" class="mr-1" 
            :variant="data.item.isActive == true ? 'info' : 'danger'"
            :icon="data.item.isActive == true ? 'CheckCircleIcon' : 'XCircleIcon'" 
            :title="data.item.isActive == true ? 'Deactivate' : 'Activate'"
            @click.stop="toggle(data.item)" />
          <delete-button v-if="$can(`super`, `nawala`)" @click.stop="remove(data.item)" />
        </template>
      </b-table>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="table.items.length" />
          <b-pagination
            v-model="table.paging.pageNo"
            :totalRows="table.items.length"
            :per-page="table.paging.pageSize"
            align="center"
            class="ml-auto"
          ></b-pagination>
        </div>
      </template>
    </b-card>

    <b-modal
      v-if="selectedRow"
      v-model="modal.status"
      :title="`${selectedRow.description} | ${selectedRow.domain}`"
      lazy
      centered
      hide-footer
      @hide="selectedRow = null"
    >
      <status-detail v-if="selectedRow" :statusList="selectedRow.statusList" @onClose="modal.status = false" />
    </b-modal>
  </div>
</template>

<script>
import { icon } from "leaflet";
import js from "./list";
export default js;
</script>
